var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.loggedIn
      ? _c("span", [_vm._v(_vm._s(_vm.getUserEmail))])
      : _c("span", [_vm._v("(anonymous)")]),
    _vm._v("   "),
    _c(
      "button",
      {
        staticStyle: { color: "red'" },
        on: {
          click: function($event) {
            return _vm.signOut()
          }
        }
      },
      [_vm._v("Click to sign out")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }