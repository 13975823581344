<template>
  <div>
    <span v-if="loggedIn">{{ getUserEmail }}</span>
    <span v-else>(anonymous)</span>&nbsp;&nbsp;
    <button style="color:red'" @click="signOut()">Click to sign out</button>
  </div>
</template>

<script>
import { auth } from "@/firebase";
// import firebase from "firebase/app";
// import "firebase/auth";

export default {
  created() {
    auth.onAuthStateChanged(user => {
      this.loggedIn = !!user;
    });
  },
  data() {
    return {
      loggedIn: false
    };
  },
  methods: {
    async signOut() {
      try {
        const data = await auth.signOut();
        console.log("/CurrentUser.signOut() with data: " + data);
        this.$router.push("/login");
      } catch (err) {
        console.log("/CurrentUser.signOut():" + err);
      }
    }
  },
  computed: {
    getUserEmail() {
      console.log(
        "/CurrentUser.computed.getUserEmail(): currentUser.email: " +
          auth.currentUser.email
      );
      return auth.currentUser.email;
    }
  }
};
</script>

<style lang="scss"></style>
