<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="info" class="py-1">
      <b-navbar-brand href="#">
        <p style="position: absolute; top: 10px; left: 60px">
          Marmalade AI
          <span style="font-size: 0.8em;">- virtual networking</span>
        </p>
        <div>
          <img
            width="48"
            height="48"
            style="position: absolute; top: 0px; left: 0px; background-color: white;"
            :src="'../assets/images/' + 'Marmalade_Logo_trans_white_M.png'"
          />
        </div>
        <br />
        <!-- <span style="font-size: 80%">
          <database-form />
        </span> -->
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-form @submit.prevent="search">
            <b-form-input
              size="sm"
              class="mr-sm-2"
              placeholder="Search word or phrase"
              v-model="searchText"
            ></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0" type="submit"
              >Search</b-button
            >
          </b-nav-form>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import DatabaseForm from "@/components/DatabaseForm.vue";

export default {
  components: {
    "database-form": DatabaseForm
  },
  methods: {
    search() {
      this.$store.dispatch("search", this.searchText);
    }
  },
  data() {
    return {
      searchText: ""
    };
  }
};
</script>

<style lang="scss" scoped>
#nav a.router-link-exact-active {
  color: white;
}
</style>
