import { render, staticRenderFns } from "./MyProfile.vue?vue&type=template&id=a287b41c&"
import script from "./MyProfile.vue?vue&type=script&lang=js&"
export * from "./MyProfile.vue?vue&type=script&lang=js&"
import style0 from "./MyProfile.vue?vue&type=style&index=0&id=a287b41c&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/fisk/github/profiles-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a287b41c')) {
      api.createRecord('a287b41c', component.options)
    } else {
      api.reload('a287b41c', component.options)
    }
    module.hot.accept("./MyProfile.vue?vue&type=template&id=a287b41c&", function () {
      api.rerender('a287b41c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/profiles/MyProfile.vue"
export default component.exports