<template>
  <div>
    <!-- <recommend_profile></recommend_profile> -->
    <current-user class="currentUser"></current-user>
    <div v-if="this.$store.state.searchText.length > 0" class="resultsCount">
      matches within your matches: {{ numberOfFilteredProfiles }} matches for
      "{{ this.$store.state.searchText }}"
    </div>
    <div v-if="this.$store.state.searchText.length == 0" class="resultsCount">
      Showing profiles sorted by match.
      {{ this.$store.state.virtual_profiles.length }}
    </div>

    <b-container>
      <profile-card
        v-for="profile in this.$store.state.virtual_profiles"
        :key="profile.uid"
        :about="profile.about"
        :conduct="profile.conduct"
        :created_timestamp="profile.created_timestamp"
        :email="profile.email"
        :last_modified_timestamp="profile.last_modified_timestamp"
        :linkedin="profile.linkedin"
        :location="profile.location"
        :meetings="profile.meetings"
        :name="profile.name"
        :photo-url="profile.photo_url"
        :position="profile.position"
        :privacy="profile.privacy"
        :score="profile.score"
        :skills="profile.skills"
        :specializations="profile.specializations"
        :terms="profile.terms"
      >
      </profile-card>
        <!-- :timezone="profile.timezone" -->
    </b-container>
  </div>
</template>

<script>
import { auth } from "@/firebase";
// import firebase from "firebase/app";
// import "firebase/auth";
import ProfileCard from "@/components/ProfileCard";
import { mapState } from "vuex";
import CurrentUser from "@/components/CurrentUser";
import RecommendProfiles from "@/components/RecommendProfiles";

const DEBUG = false;

export default {
  data() {
    return {
      search: ""
    };
  },
  async beforeCreate() {
    if (DEBUG) {
      getAllMatches("Profiles.beforeCreate");
    }
  },
  async created() {
    if (DEBUG) {
      console.log("Profiles.created");
    }
  },
  async beforeMount() {
    if (DEBUG) {
      console.log("DEBUG switch is on, in Profiles.beforeMount");
    }
    console.log(
      ">>>>>>>>>>>>>Starting profiles.beforeMount(); this.$store.state,profiles.length: ",
      this.$store.state.profiles.length
    );
    console.log("Profiles.beforeMount() calling this.onSubmitting, which calls this.$root.$refs.MyProfile.onSubmit()");
    console.log("And then this.sendingRequest, which calls this.$root.$refs.MyProfile.sendRequest");
        await this.onSubmitting();
    // await this.updatingFirebase();
    await this.sendingRequest();

    console.log(
      ">>>>>>>>>>>>>Ending profiles.beforeMount(); this.$store.state,profiles.length: ",
      this.$store.state.profiles.length
    );
  },
  async mounted() {
    if (DEBUG) {
      console.log("Profiles.mounted");
    }
  },
  beforeUpdate() {
    if (DEBUG) {
      console.log("Profiles.beforeUpdate");
    }
  },
  async updated() {
    // Fires every time the data changes!
    if (DEBUG) {
      console.log("Profiles.updated");
    }
  },
  async activated() {
    if (DEBUG) {
      console.log("Profiles.activated");
    }
  },
  async deactivated() {
    if (DEBUG) {
      console.log("Profiles.deactivated");
    }
  },
  beforeDestroy() {
    if (DEBUG) {
      console.log("Profiles.beforeDestroy");
    }
  },
  destroyed() {
    if (DEBUG) {
      console.log("Profiles.destroyed");
    }
  },
  errorCaptured() {
    if (DEBUG) {
      console.log("Profiles.errorCaptured");
    }
  },
  components: { "profile-card": ProfileCard, "current-user": CurrentUser, "recommend_profile": RecommendProfiles },
  computed: {
    ...mapState(["profiles"]),
    getUserUID() {
      let uid = auth.currentUser.uid;
      console.log("At getUserUID in App.vue. uid: " + uid);
      return uid;
    },
    filteredProfiles: function() {
      console.log(
        "/profiles.filteredProfiles(), virtual_profiles.length: <<<, ",
        this.$store.state.virtual_profiles.length
      );
      return this.$store.state.virtual_profiles.filter(profile => {
        // return this.profiles.filter(profile => {
        // on match():
        // MDN: The match() method retrieves the result of matching a string against a regular expression.
        // Returns an array or null. If no "g" flag, only the first complete match is returned.
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/match
        const regexSearch = new RegExp(this.$store.state.searchText, "i");
        return (
          (profile.location ? profile.location.match(regexSearch) : null) ||
          (profile.name ? profile.name.match(regexSearch) : null) ||
          (profile.position ? profile.position.match(regexSearch) : null) ||
          (profile.about ? profile.about.match(regexSearch) : null)
        );
      });
    },
    numberOfFilteredProfiles: function() {
      return this.filteredProfiles.length;
    }
  },
  methods: {
    // modifiedString() {
    //   this.$store.state.testString =
    //     "Ok, this is the currently modified string";
    //   return this.$store.state.testString;
    // },
    sendingRequest: function() {
      console.log("Profiles.sendingRequest");
      this.$root.$refs.MyProfile.sendRequest();
    },
    updatingFirebase: function() {
      console.log("Profiles.updatingFirebase");
      this.$root.$refs.MyProfile.updateFirebase();
    },
    onSubmitting: function () {
      console.log("Profiles.onSubmitting");
      this.$root.$refs.MyProfile.onSubmit();
    }
  }
};
</script>

<style lang="scss">
// .currentUser {
//   margin-top: -15px;
//   margin-left: 20px;
//   margin-bottom: 10px;
// }
// .resultsCount {
//   color: red;
//   margin-top: -15px;
//   margin-left: 20px;
//   margin-bottom: 10px;
// }
</style>
