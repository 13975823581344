var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _c("form", { staticStyle: { display: "inline-block" } }, [
      _c("label", { staticClass: "navbar_database_prompt" }, [
        _vm._v("Database: ")
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.datasource,
            expression: "datasource"
          }
        ],
        attrs: { type: "datasource", required: "" },
        domProps: { value: _vm.datasource },
        on: {
          input: function($event) {
            if ($event.target.composing) return
            _vm.datasource = $event.target.value
          }
        }
      }),
      this.$route.name == "login" || this.$route.name == "register"
        ? _c("div", [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.datasource,
                    expression: "datasource"
                  }
                ],
                staticStyle: { background: "transparent" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.datasource = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                _c("option", { attrs: { value: "marmalade-dev" } }, [
                  _vm._v("marmalade-dev")
                ]),
                _c("option", { attrs: { value: "marmalade-production" } }, [
                  _vm._v("marmalade-production")
                ])
              ]
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }