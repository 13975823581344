var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "div",
        { attrs: { id: "nav" } },
        [
          _c("router-link", { attrs: { to: "/profiles" } }, [
            _vm._v("Profiles")
          ]),
          _vm._v(" | "),
          _c("router-link", { attrs: { to: "/myprofile" } }, [
            _vm._v("MyProfile")
          ]),
          _vm._v(" | "),
          _c("router-link", { attrs: { to: "/login" } }, [_vm._v("Login")]),
          _vm._v(" | "),
          _c("router-link", { attrs: { to: "/register" } }, [
            _vm._v("Register")
          ]),
          _vm._v(" | "),
          _c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")]),
          _c("navigation-bar")
        ],
        1
      ),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }