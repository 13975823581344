<template>
  <div>
    <b-col>
      <b-card no-body style="max-width: 30rem, border: none;" class="md-3">
        <b-card-body no-body style="border: none">
          <b-row style="border: none">
            <b-col>
              <b-card-title class="card-listing"
                ><span style="color: #f97600"
                  ><b>Marmalade AI</b></span
                ></b-card-title
              >
              <br />
              <b-card-sub-title style="font-size: 1.3em">
                <b>Mission</b><br /><br />
              </b-card-sub-title>
            </b-col>
          </b-row>
          <b-row>
            <b-card-text style="padding-left: 15px; font-size: 0.9em">
              <p>
                Marmalade AI, Inc.&apos;s mission is to use <b>virtualization</b> to
                enable a <i>sea change</i> in how we work. How? Given your
                <b>profile</b> <a href="#section1">[1]</a>, Marmalade creates a <b>virtual network</b> <a href="#section2">[2]</a>.
                Accurate, automatic, and appropriate -- people with whom you can work and share
                practices.
              </p>
              <p>
                How does Marmalade do this? By using <b>NLP</b> to create matches based on the text of your
                profile. It&apos;s accurate and automatic. All you have
                to do is describe what you do. And... your virtual network changes a) as the platform grows and b) whenever
                you change your profile.
              </p>
              <p>The only requirement is to have a profile (see above). Your <b>virtual network</b>
                automatically reflects your profile.
              </p>
              <br />
              <b-card-sub-title style="font-size: 1.3em">
                <b>What to expect in <i>Early Access</i>:</b><br /><br />
              </b-card-sub-title>
              <p>
                You are grandfathered in -- free forever -- as an Early
                Access user, if at least one other person indicates you invited him or her to join (mutual is fine, too).
                So invite people you think should be part of
                this, based on having the same specialization. 
                Have suggestions: <a href="mailto:alpha@marmalade.ai">email feedback</a>.
              <br />
              <p id="section1">[1] A "profile" could be cut and paste from a Linkedin &quot;About&quot;, 
                etc., or written from scratch. It should say what you do.</p>
              <p id="section2">[1] What is different about "virtual"? It's more efficient and more accurate. Instead of building a network one meeting at a time, your virtual network is instantaneous.</p>
            <p>&copy; 2022 Marmalade AI, Inc.</p>
            </b-card-text>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.b-col {
  border: 4px solid black;
}
// h4 {
//   font-weight: 600;
//   color: #37827b;
// }
// .b-card-img {
//   width: 100%;
//   height: 15vw;
//   object-fit: cover;
// }
// .card-listing {
//   color: black;
//   font-size: 28px;
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: left;
//   color: #2c3e50;
// }
// .title {
//   margin-left: 20px;
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: left;
//   color: #2c3e50;
// }
</style>
