var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-col",
        [
          _c(
            "b-card",
            {
              staticClass: "md-3",
              staticStyle: { "max-width": "30rem, border: none" },
              attrs: { "no-body": "" }
            },
            [
              _c(
                "b-card-body",
                { staticStyle: { border: "none" }, attrs: { "no-body": "" } },
                [
                  _c(
                    "b-row",
                    { staticStyle: { border: "none" } },
                    [
                      _c(
                        "b-col",
                        [
                          _c("b-card-title", { staticClass: "card-listing" }, [
                            _c("span", { staticStyle: { color: "#f97600" } }, [
                              _c("b", [_vm._v("Marmalade AI")])
                            ])
                          ]),
                          _c("br"),
                          _c(
                            "b-card-sub-title",
                            { staticStyle: { "font-size": "1.3em" } },
                            [_c("b", [_vm._v("Mission")]), _c("br"), _c("br")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-card-text",
                        {
                          staticStyle: {
                            "padding-left": "15px",
                            "font-size": "0.9em"
                          }
                        },
                        [
                          _c("p", [
                            _vm._v(" Marmalade AI, Inc.'s mission is to use "),
                            _c("b", [_vm._v("virtualization")]),
                            _vm._v(" to enable a "),
                            _c("i", [_vm._v("sea change")]),
                            _vm._v(" in how we work. How? Given your "),
                            _c("b", [_vm._v("profile")]),
                            _vm._v(" "),
                            _c("a", { attrs: { href: "#section1" } }, [
                              _vm._v("[1]")
                            ]),
                            _vm._v(", Marmalade creates a "),
                            _c("b", [_vm._v("virtual network")]),
                            _vm._v(" "),
                            _c("a", { attrs: { href: "#section2" } }, [
                              _vm._v("[2]")
                            ]),
                            _vm._v(
                              ". Accurate, automatic, and appropriate -- people with whom you can work and share practices. "
                            )
                          ]),
                          _c("p", [
                            _vm._v(" How does Marmalade do this? By using "),
                            _c("b", [_vm._v("NLP")]),
                            _vm._v(
                              " to create matches based on the text of your profile. It's accurate and automatic. All you have to do is describe what you do. And... your virtual network changes a) as the platform grows and b) whenever you change your profile. "
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              "The only requirement is to have a profile (see above). Your "
                            ),
                            _c("b", [_vm._v("virtual network")]),
                            _vm._v(" automatically reflects your profile. ")
                          ]),
                          _c("br"),
                          _c(
                            "b-card-sub-title",
                            { staticStyle: { "font-size": "1.3em" } },
                            [
                              _c("b", [
                                _vm._v("What to expect in "),
                                _c("i", [_vm._v("Early Access")]),
                                _vm._v(":")
                              ]),
                              _c("br"),
                              _c("br")
                            ]
                          ),
                          _c("p", [
                            _vm._v(
                              " You are grandfathered in -- free forever -- as an Early Access user, if at least one other person indicates you invited him or her to join (mutual is fine, too). So invite people you think should be part of this, based on having the same specialization. Have suggestions: "
                            ),
                            _c(
                              "a",
                              { attrs: { href: "mailto:alpha@marmalade.ai" } },
                              [_vm._v("email feedback")]
                            ),
                            _vm._v(". "),
                            _c("br")
                          ]),
                          _c("p", { attrs: { id: "section1" } }, [
                            _vm._v(
                              '[1] A "profile" could be cut and paste from a Linkedin "About", etc., or written from scratch. It should say what you do.'
                            )
                          ]),
                          _c("p", { attrs: { id: "section2" } }, [
                            _vm._v(
                              '[1] What is different about "virtual"? It\'s more efficient and more accurate. Instead of building a network one meeting at a time, your virtual network is instantaneous.'
                            )
                          ]),
                          _c("p", [_vm._v("© 2022 Marmalade AI, Inc.")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }