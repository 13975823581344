<template>
  <div>
    <current-user class="currentUser"></current-user>
    <div v-if="this.$store.state.searchText.length > 0" class="resultsCount">
      "Profiles" tab shows {{ numberOfFilteredProfiles }} subset of matches for
      "{{ this.$store.state.searchText }}"
    </div>
    <div v-if="this.$store.state.searchText.length == 0" class="resultsCount">
      "Profiles" tab shows top profiles ranked by match.
    </div>
    <b-container style="padding: 0px 0px 0px 0px">
      <div class="edit_form">

        <!-- Change updateFirebase to handleSubmit -->
        <ValidationObserver name="myform" v-slot="{ handleSubmit }">
        <form id="myform" @submit.prevent="handleSubmit(onSubmit)">
          <div class="edit_fields">
        
            <ValidationProvider name="name" :rules="{ required: true, regex: /^[A-Za-zА-Яа-яЁё]((\ |\-)?[A-Za-zА-Яа-яЁё ]){0,49}$/ }" v-slot="{ errors }">
            <br />
            <label>Name:</label>
            <input
              id="idperson"
              type="text"
              name="name"
              v-model="formData.name"
              placeholder="<name>"
              autocomplete="off"
              required
            /><span>{{ errors[0] }}</span>
            <br />
            </ValidationProvider>


            <label>Photo:</label><br />
            <div>
              <b-img :src=formData.photo_url fluid alt="Responsive image"></b-img>
            </div>  

            <ValidationProvider name="photo" rules="required" v-slot="{ errors }">
            <input 
              required 
              id="input" 
              type="file" 
              accept="image/png, image/jpeg, image/jpg" 
              @change="photoUpload"
              :disabled="processing" 
            />
            <span>{{ errors[0] }}</span>
            <br />
            </ValidationProvider>


            <label>Location:</label>
            <ValidationProvider name="location" rules="required" v-slot="{ errors }">
            <input
              required
              type="text"
              name="location"
              v-model="formData.location"
              placeholder="<location>"
            /><span>{{ errors[0] }}</span>
            <br />
            </ValidationProvider>


            <label>Timezone:</label>
            <ValidationProvider name="timezone" rules="required" v-slot="{ errors }">
            <input
              required
              type="text"
              name="timezone"
              v-model="formData.timezone"
              placeholder="<timezone abbreviation>"
            /><span>{{ errors[0] }}</span>
            <br />
            </ValidationProvider> 


            <label>Email:</label>
            <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
            <input
              type="email"
              name="email"
              v-model="formData.email"
              placeholder="<email>"
            /><span>{{ errors[0] }}</span>
            <br />
            </ValidationProvider>

            
            <!-- <label>Profile:</label><br /> -->
            <!-- on auth-resize in Vue, see https://www.youtube.com/watch?v=HDiPOkFIP60
            by CodeCpurse. See 6:50+ on preferred method, which is creating an auto-resizing
            textarea component. By CodeCourse.com = Alex Garrett-Smith @alexjgarrett -->
            <!-- class="<profile>" -->

            <ValidationProvider name="about" rules="required|min:400" v-slot="{ errors }">
            <textarea
              style="
                font-size: 0.8em;
                width: 100%;
                max-width: 100%;
              "
              id="message"
              name="about"
              v-model="formData.about"
              placeholder="<Copy and paste your profile here, or write from scratch>"
              required
            ></textarea> 
            <span>{{ errors[0] }}</span>
            <br />
            </ValidationProvider>


            <label>Specializations:</label>
            <div>
              <span v-for="(specialization, index) in formData.specializations" :key="specialization.id">
                <u>{{ specialization }}</u><span v-if="index !== (formData.specializations.length - 1)"> | </span>
              </span>
            </div>

            <label>Skills ({{formData.skills.length}}):</label>
            <div>
              <span v-for="(skill, index) in formData.skills" :key="skill.id">
                <u>{{ skill }}</u><span v-if="index !== (formData.skills.length - 1)"> | </span>
              </span>
            </div>

            <div>
              <ValidationProvider name="privacy" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }">
                <b-form-checkbox id="idprivacy" type="checkbox" v-model="formData.privacy" required:true name="privacy" value=true
                  unchecked-value=false> I accept the privacy conditions: <a
                    href="https://marmalade.ai/privacy-policy">Privacy Policy</a>
                </b-form-checkbox>
                <span>{{ errors[0] }}</span>
              </ValidationProvider>
              <div v-if="formData.privacy">State: {{ formData.privacy }}</div>
              <div v-else>State: (not accepted)</div>
            </div>
            <div>
              <ValidationProvider name="terms" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }">
                <b-form-checkbox id="idterms" type="checkbox" v-model="formData.terms" required:true name="terms" value=true
                  unchecked-value=false> I accept the terms and conditions
                </b-form-checkbox>
                <span>{{ errors[0] }}</span>
              </ValidationProvider>
              <div v-if="formData.terms">State: {{ formData.terms }}</div>
              <div v-else>State: (not accepted)</div>
            </div>
            <div>
              <ValidationProvider name="conduct" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }">
                <b-form-checkbox id="idconduct" type="checkbox" v-model="formData.conduct" required:true name="conduct" value=true
                  unchecked-value=false> I accept code of conduct: <a
                    href="https://marmalade.ai/code-of-conduct">Code of Conduct</a>
                </b-form-checkbox>
                <span>{{ errors[0] }}</span>
              </ValidationProvider>
              <div v-if="formData.conduct">State: {{ formData.conduct }}</div>
              <div v-else>State: (not accepted)</div>
            </div>
          <br />
          <br />      
          </div>
        </form>
        </ValidationObserver>
      </div>
    </b-container>
  </div>
</template>
<!-- <script type="module" src="./MyProfile.vue"> -->
<script>
import { projectStorage, auth, db } from "../../firebase";
import { mapState } from "vuex";
import CurrentUser from "@/components/CurrentUser";
import firebase from "firebase";
import { Field, Form } from "vee-validate";
// import firebase from "firebase/app";
// import RecommendProfiles from "@/components/RecommendProfiles";
const DEBUG = false;

export default {
  data() {
    return {
      validation_errors: [],
      storage: null,
      state: "loading",
      processing: false,
      search: "",
      // fields from profiles collection
      formData: {
        about: "",
        // contact_info: "",
        conduct: true,
        created_timestamp: "",
        email: "",
        last_modified_timestamp: "",
        linkedin: "",
        location: "",
        // meetings: "",
        name: "",
        photo_url: "", //"https://storage.googleapis.com/marmalade-dev.appspot.com/kitty.jpg",
        portfolio: [],
        position: "",
        privacy: true,
        skills: [],
        digital_marketing_options: [
          { item: "branding", name: "Branding" },
          { item: "collateral", name: "Collateral" }
        ],
        specializations: [],
        specializations_options: [
          { item: "digital marketing", name: "digital marketing" }
        ],
        terms: true,
        timezone: "PST"
      }
    };
  },
  beforeCreated() {},
  created() {
    this.$root.$refs.MyProfile = this;
  },
  beforeMount() {},
  async mounted() {
  // mounted: async function() {
    // The key is to wait for resolution Using async/await in lifecycle hooks. See
    // On async/await in Vue lifecycle hooks, see Yair Cohen on Stackoverflow:
    // https://stackoverflow.com/questions/65639724/how-to-use-async-await-in-vue-lifecycle-hooks-with-vuex
    //
    // Also
    // Lukas Herman, "How to use Async/Await with Vue.js Components,"
    // https://lukashermann.dev/writing/how-to-use-async-await-with-vuejs-components/
    // 
    // Also, Fireship seems to say to use "created"
    // But. apparently the created hook doesn't allow async calls; only mounted does
    // On lifecycle hooks: https://www.digitalocean.com/community/tutorials/vuejs-component-lifecycle
    if (DEBUG) {
      console.log("---MyProfile.mounted. calling an outside API, storing data");
    }

    const myStorage = projectStorage;
    // const myStorage = firebase.app().storage;
    this.storage = myStorage;
    // On vm, 
    // see https://stackoverflow.com/questions/36176073/what-is-vue-way-to-access-to-data-from-methods
    // and results from https://www.google.com/search?q=How+do+I+use+vm+in+vue
    var vm = this;
    const email = auth.currentUser.email;
    console.log("/MyProfile.mounted() auth.currentUser.email is: ", email);
    await db
      .collection("profiles")
      .where("email", "==", auth.currentUser.email)
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach(function (doc) {
          console.log(
            "/MyProfile.mounted() Getting document values from Firestore for this auth.currentUser.email."
          );
          console.log("doc.data().timezone is: " + doc.data().timezone);
          console.log("/MyProfile.mounted():");
          console.log(`${doc.id} => ${doc.data().email}`);
          console.log("MyProfile.mounted, doc.data().email: %s and doc.data().skills: %s", doc.data().email, doc.data().skills);
          vm.formData.about = doc.data().about;
          vm.formData.conduct = doc.data().conduct;
          vm.formData.contact_info = doc.data().contact_info;
          vm.formData.created_timestamp = doc.data().created_timestamp;
          vm.formData.email = doc.data().email;
          // vm.formData.id = doc.data().id;
          vm.formData.last_modified_timestamp = doc.data().last_modified_timestamp;
          vm.formData.linkedin = doc.data().linkedin;
          vm.formData.location = doc.data().location;
          // vm.formData.meetings = doc.data().meetings;
          vm.formData.name = doc.data().name;
          vm.formData.photo_url = doc.data().photo_url;
          vm.formData.portfolio = doc.data().portfolio;
          vm.formData.position = doc.data().position;
          vm.formData.privacy = doc.data().privacy;
          vm.formData.skills = doc.data().skills;
          vm.formData.specializations = doc.data().specializations;
          vm.formData.terms = doc.data().terms;
          vm.formData.timezone = doc.data().timezone;
        });
      })
      .catch(function(error) {
        console.log("/MyProfile mounted(): Error: ", error);
      });
    if (location.hostname === "localhost") {
      console.log("Detected localhost");
      debugger;
    } else {
      console.log("Did not detect localhost");
      debugger;
    }
    console.log("MyProfile mounted -- end; vm.formData.photo_url: ", vm.formData.photo_url);
    return null;
  },
  beforeUpdate() {},
  async updated() {
    console.log(
      "MyProfile.updated; use for changes to the data, so calling ####### 'updatingFirebase()'"
    );
    await this.updatingFirebase();
  },
  beforeDestroy() {
    console.log("MyProfile.beforeDestroy"); // and foo is: ", this.foo());
  },
  destroyed() {
  },
  methods: {
    onSubmit() {
      console.log("Running onSubmit() method.");
    },
    getMyProfile: async function() {
      var myprofile = [];
      await db
        .collection("profiles")
        .where("email", "==", auth.currentUser.email)
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            myprofile = doc.data();
          });
        })
        .catch(function(error) {
          console.log("/MyProfile.getMyProfile error:", error);
        });
      console.log(
        "MyProfile.getMyProfile and myprofile.email: ",
        myprofile.email
      );
      console.log("MyProfile.getMyProfile, myprofile.email: %s and myprofile.name: %s", myprofile.email, myprofile.name);
      return myprofile;
    },
    // foo: function() {
    //   console.log("foo function: getMyProfile returns: ", this.getMyProfile());
    // },
    updatingFirebase: function() {
      console.log("MyProfile.updatingFirebase");
      this.$root.$refs.MyProfile.updateFirebase();
    },
    async photoUpload(e) {
      try {
        const file = e.target.files[0];
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(file.name);
        await fileRef.put(file);
        let myUrl = "";
        myUrl = await fileRef.getDownloadURL();
        this.formData.photo_url = myUrl;
      } catch (e) {
        console.error("In the error and e is: " + e);
      } finally {
        this.processing = false;
      }
    },
    // NOTE: add *debounce* following fireship.io
    async updateFirebase() {
      console.log(
        "/MyProfile.methods.updateFirebase() name: ",
        this.formData.name
      );
      // Note: Not handling the case where the user changes their email!
      //
      // On querySnapshot
      // https://firebase.google.com/docs/reference/js/firebase.firestore.QuerySnapshot
      // A QuerySnapshot contains zero or more DocumentSnapshot objects representing the
      // results of a query. The documents can be accessed as an array via the docs property
      // or enumerated using the forEach method. The number of documents can be determined
      // via the empty and size properties.
      // ToDo: Check for uid field in 0, 1, or 1+ records on login; complementary to below code.
      await db.collection("profiles")
        .where("email", "==", this.formData.email)
        .get()
        .then(querySnapshot => {
          if (querySnapshot.empty) {
            console.log(
              "/MyProfile.methods.updateFirebase() querySnapshot is empty, probably because there is no uid field"
            );
          } else if (querySnapshot.size > 1) {
            console.log(
              "/MyProfile.methods.updateFirebase() More than one record with the same uid"
            );
          } else {
            console.log(
              "/MyProfile.methods.updateFirebase() Normal update with one (1) user, starting here."
            );
            querySnapshot.forEach(doc =>
              doc.ref.update({
                about: this.formData.about,
                // contact_info: this.formData.contact_info,
                conduct: this.formData.conduct,
                created_timestamp: this.formData.created_timestamp,
                email: this.formData.email,
                last_modified_timestamp: this.formData.last_modified_timestamp,
                linkedin: this.formData.linkedin,
                location: this.formData.location,
                // meetings: this.formData.meetings,
                name: this.formData.name,
                photo_url: this.formData.photo_url,
                portfolio: this.formData.portfolio,
                position: this.formData.position,
                privacy: this.formData.privacy,
                skills: this.formData.skills,
                specializations: this.formData.specializations,
                terms: this.formData.terms,
                timezone: this.formData.timezone
              })
            );
          }
        });
      // On "this" losing scope in nested JavaScript functions, see
      // https://www.freecodecamp.org/news/what-to-do-when-this.loses-context-f09664af076f/
      // and https://www.pluralsight.com/guides/javascript-callbacks-variable-scope-problem
      // Docs: https://firebase.google.com/docs/firestore/manage-data/add-data
      // See Frank van Puffelen https://stackoverflow.com/questions/59017373/how-to-update-collection-documents-in-firebase-in-flutter
      // console.log(
      //   "/MyProfile.methods.updateFirebase() *end* with skills: ",
      //   this.formData.skills
      // );
    },
    intersection: function(setA, setB) {
      let _intersection = new Set();
      for (let elem of setB) {
        if (setA.has(elem)) {
          _intersection.add(elem);
        }
      }
      return _intersection;
    },
    // Use a deep copy, convert skills to lowercase, sort(), use Sorensen's
    // coefficient to create a match scoore between 0 and 1.0.
    getScores: async function(myprofile, profiles, number) {
      console.log("/MyProfile.methods.getScores() start");
      // Convert my skills to lowercase and put in alphabetical order;
      await profiles.forEach(profile => {
        var lowerCaseSkills = [];
        // Check myprofile.email here
        if (Array.isArray(profile)) {
          if (!(profile.skills.length > 0)) {
            console.log("In profile.email record with skills !> 0");
          } else {
            profile.skills.forEach(skill => {
              // console.log('Skill: '. skill);
              var strTemp = skill.toLowerCase();
              lowerCaseSkills.push(strTemp);
            })
          }
          profile.skills = lowerCaseSkills.sort();
        }
      });
      
      // Convert to Set()
      myprofile.skillset = new Set(myprofile.skills);
      profiles.forEach(profile => {
        profile.skillset = new Set(profile.skills);
      });
      // Get the Set() intersection
      profiles.forEach(profile => {
        var intersectionSet = this.intersection(
          myprofile.skillset,
          profile.skillset
        );
        // Enusre that the intersection set always has some value
        let intersections = intersectionSet.size ? intersectionSet.size : 0;
        // Calculate Sorensen-Dice score coefficient:
        let score = 0.0;
        // Apply Sorenson-Dice algorithm to obtain a score.
        score =
          (2 * intersections) /
          (myprofile.skillset.size + profile.skillset.size);
        profile.score = score;
      });
      // Sort the profiles by score:
      profiles.sort(function(a, b) {
        if (a.score < b.score) return 1;
        if (a.score > b.score) return -1;
        return 0;
      });
      // console.log("/MyProfile.methods.updateFirebase() return profiles");
      return profiles.slice(0, number);
    },
    async sendRequest() {
      console.log("+++++++++/MyProfile.sendRequest()");
      // On arrow functions in Vue: How to access another function, like getScore()? ...use 
      // an arrow function, if ES6, since arrow functions use “this” value of the outer scope:
      console.log(
        "In sendRequest, this.$store.state,profiles.length: ",
        await this.$store.state.profiles.length
      );

      let p = await JSON.parse(JSON.stringify(this.$store.state.profiles));

      // Filter current user out of "profiles":
      var profiles = await p.filter(function(obj) {
        return obj.email !== auth.currentUser.email;
      });
      console.log(
        "In sendRequest, auth.currentUser.email: ",
        auth.currentUser.email
      );
      var myprofile = await this.getMyProfile();
      
      // Switch here between cloud function (mocked) and local matching

      // var profiles_scored = ...
      // Takes myprofile.id (string) and a number to return
      // local:
      var profiles_scored = await this.getScores(myprofile, profiles, 30);

      console.log("typeof profiles_scored: ", typeof profiles_scored);
      this.$store.state.virtual_profiles = await profiles_scored;
      console.log("/MyProfile.methods.sendRequest() end");
    }
  },
  components: { "current-user": CurrentUser },
  computed: {
    ...mapState(["profiles"]),
    getUserUID() {
      let uid = auth.currentUser.uid;
      console.log("/MyProfile.computed.getUserUID() uid: " + uid);
      return uid;
    },
    onLoginOrRegister() {
      return (this.$route.name == "login" || this.$route.name == "register");
    }
  }
};
</script>

<style lang="scss">
input[type="file"] {
  color: rgba(0, 0, 0, 0)
}
// form {
//   max-width: 420px;
//   // margin: 30px auto;
//   background: white;
//   text-align: left;
//   // padding: 40px;
//   // border-radius: 10px;
// }
// label {
//   color: #green;
//   // display: inline-block;
//   // margin: 25px 0 15px;
//   font-size: 0.6em;
//   text-transform: uppercase;
//   letter-spacing: 1px;
//   font-weight: bold;
// }
// input {
//   // display: block;
//   // padding: 10px 6px;
//   width: 100%;
//   // box-sizing: border-box;
//   border: none;
//   border-bottom: 1px solid #ddd;
//   color: #555;
// }
// // end Net Ninja
// .edit_form {
//   color: green;
//   margin-left: 20px;
//   font-family: Avenir, Helvetica, Arial, sans-serif;
// }
// .currentUser {
//   margin-top: -15px;
//   margin-left: 20px;
//   margin-bottom: 10px;
// }
// .resultsCount {
//   color: red;
//   margin-top: -15px;
//   margin-left: 20px;
//   margin-bottom: 10px;
// }
// button {
//   background: #0b6dff;
//   border: 0;
//   padding: 10px 20px;
//   margin-top: 20px;
//   color: white;
//   border-radius: 20px;
// }
// .submit {
//   // text-align: center;
// }
// textarea {
//   // max-height: 100px;
//   // width: 300px;
//   height: 380px;
// }
// input {
//   width: 300px;
// }
// .profile {
//   text-rendering: auto;
//   letter-spacing: normal;
//   word-spacing: normal;
//   text-transform: none;
//   text-indent: 2px;
//   display: inline-block;
// }
</style>
