<template>
  <div class="login_form">
    <div v-if="error" class="error">{{ error.message }}</div>
    <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="register">
    <!-- Register -->
    <div class="email">
      <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
      <input type="email" v-model="email" placeholder="email" />
        <span>{{ errors[0] }}</span>
      </ValidationProvider>
    </div>
    <div class="password">
      <ValidationProvider name="password" rules="required|max:40|min:7" vid="password" v-slot="{ errors }">
      <input type="password" autocomplete="off" v-model="password" placeholder="password" />
        <span>{{ errors[0] }}</span>
      </ValidationProvider>
    </div>
    <!-- <div class="password">
      <ValidationProvider name="password_confirmation" rules="required|confirmed:password" v-slot="{ errors }">
      <input type="password" autocomplete="off" ref="password" v-model="password_confirmation" placeholder="password, again to confirm" />
        <span>{{ errors[0] }}</span>
      </ValidationProvider>
    </div> -->

    <button type="submit" @click="register">Register</button>
    </form>
  </ValidationObserver>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { auth } from "@/firebase";
import firebase from "firebase";
// import firebase from "firebase/app";

export default {
  data() {
    return {
      email: "",
      password: "",
      // password_confirmation: "",
      error: ""
    };
  },
  methods: {
    register() {
      try {
        console.log("Current database project id: " + JSON.stringify(db.app.name, null, 2));
        console.log("Store database is: " + this.$store.state.database);
        auth.createUserWithEmailAndPassword(this.email, this.password)
        .then(function(data) {
          console.log("Here is data.user.uid: " + data.user.uid);
        var qry = db
          .collection("profiles")
          .add({
            about: "",
            conduct: "not accepted",
            contact_info: new Array(),
            created_timestamp: "", //db.FieldValue.serverTimestamp(),
            email: data.user.email,
            id: data.user.uid,
            last_modified_timestamp: "",
            linkedin: "",
            location: "",
            meetings: new Array(),
            name: "",
            photo_url: "",
            portfolio: new Array(),
            position: "",
            privacy: "not accepted",
            skills: new Array(),
            specializations: new Array(),
            terms: "not accepted",
            timezone: ""
        })
        .then((docRef) => {
          console.log("Document written with id: " + docRef.id);
          docRef.update({ id: docRef.id });
          this.$router.push("/myprofile");
        })
        .catch((error) => {
          console.log("Error adding document: " + error);
        });
        return qry;
        })
        .then(() => {
          this.$router.push("/myprofile");
        })
        .catch(function(error) {
          console.log("Error from auth async block: " + error);
        });
      } catch (err) {
        console.log("Error from try block:" + err);
      }
    }
  },
  computed: {
    getUserUID() {
      let uid = auth.currentUser.uid;
      console.log("At getUserUID in App.vue. uid: " + uid);
      return uid;
    }
  }
};
</script>

<style lang="scss">
// .login_form {
//   // color: orange;
//   margin-left: 20px;
//   font-family: Avenir, Helvetica, Arial, sans-serif;
// }
</style>
