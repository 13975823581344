<template>
  <div id="app">
    <!-- <DatabaseForm /> -->
    <div id="nav">
      <!-- <current-user></current-user> -->
      <router-link to="/profiles">Profiles</router-link> |
      <router-link to="/myprofile">MyProfile</router-link> |
      <!-- Note: to concatenate inside a string, need to bind and do it this way. -->
      <!-- <router-link :to="'/profiles/:' + getUserUID">My Profile</router-link> | -->
      <router-link to="/login">Login</router-link> |
      <router-link to="/register">Register</router-link> |
      <router-link to="/">Home</router-link>
      <navigation-bar></navigation-bar>
    </div>
    <router-view />
  </div>
</template>

<script>
import { db } from "@/firebase";
// import firebase from "firebase/app";
// import "firebase/auth";
import NavigationBar from "@/components/NavigationBar.vue";

// Replaced computed: getUserUID() 
import CurrentUser from "@/components/CurrentUser.vue";
import { mapGetters } from "vuex";
import { mapState } from "vuex";
// import axios from "axios";

export default {
  name: "App",
  async mounted() {
    console.log(
      "/App.mounted(), db is: " + JSON.stringify(db.app.name, null, 2)
    );
    this.$store.dispatch("bindProfiles", db);
    // CF20220227 adding for CORS in development, per
    // https://betterprogramming.pub/what-you-need-to-know-about-cors-and-vue-to-boost-browser-security-7c216252b20d
    // Use a relative path to call the API now since our request is proxied.
    // const response = await axios.get("/profile_recommender_service");
    // // this.quote = response.data.quote;
    // this.profile_recommender_service =
    //   response.data.profile_recommender_service;
  },
  computed: {
    ...mapGetters(["profiles"]),
    ...mapState(["profiles"])
  },
  components: {
    "navigation-bar": NavigationBar,
    "current-user": CurrentUser
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

/* was 30px */
#nav {
  padding: 20px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.page {
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  z-index: 25;
  width: 100%;
  height: 100%;
}
// import from MyProfile: .vue;
form {
  max-width: 420px;
  // margin: 30px auto;
  // background: white;
  text-align: left;
  padding: 0px;
  // border-radius: 10px;
}
label {
  color: green;
  // display: inline-block;
  // margin: 25px 0 15px;
  font-size: 0.8em;
  // text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  padding: -5px 0px;
  text-decoration: underline;
}
input {
  // display: block;
  padding: 0px 5px;
  width: 100%;
  // box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ddd;
  color: #555;
  font-weight: 600;
  padding: 0px 0px;
  width: 300px;
}
// end Net Ninja
.edit_form {
  color: green;
  margin-left: 20px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.currentUser {
  margin-top: -15px;
  margin-left: 20px;
  margin-bottom: 10px;
}
.resultsCount {
  color: red;
  margin-top: -15px;
  margin-left: 20px;
  margin-bottom: 10px;
}
button {
  // background: #0b6dff;
  background: #17a2b8;
  border: 0;
  padding: 2px 5px; // 10px 15px;
  margin-top: 10px;
  color: white;
  border-radius: 20px;
}
.submit {
  // text-align: center;
}
textarea {
  // max-height: 100px;
  // width: 300px;
  height: 380px;
  // font-size: 0.8em;
  font-size: 8px;
  font-color: red;
}
.profile {
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 2px;
  display: inline-block;
}
.login_form {
  // color: orange;
  margin-left: 20px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
::-webkit-input-placeholder {
  font-weight: 100;
  font-style: italic;
  font-size: 0.8em;
}
:-moz-placeholder {
  font-style: italic;
  font-size: 0.8em;
  font-weight: 100;
}
::-moz-placeholder {
  font-weight: 100;
  font-style: italic;
  font-size: 0.8em;
}
:-ms-input-placeholder {
  font-weight: 100;
  font-style: italic;
  font-size: 0.8em;
}
.select_field {
  font-size: 1em;
  font-weight: 700;
}
</style>
