import { firebase, auth } from "./firebase";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import { firebase, auth } from "./firebase";
// import firebase from "firebase/app";
// import "firebase/auth";
import "./plugins/bootstrap-vue";
import "../assets/scss/custom.scss";
import { ValidationObserver } from 'vee-validate'
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.config.productionTip = false;

Vue.use(firebase);
let app;

// Erik Hanchett's technique for ensuring that there is no delay in knowing which user
// is currently logged in (sde 36:00+ in https://www.youtube.com/watch?v=XtbYBoKb2zY),
// but I still have an issue with needing to "refresh" the browser to get the
// current user.
auth.onAuthStateChanged(function(user) {
  console.log("/main app wrapper and user is: " + user);
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount("#app");
  }
});
