var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cardIndent" }, [
      _c("h2", [_vm._v("404")]),
      _c("h3", [_vm._v("Page not found")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }