var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("current-user", { staticClass: "currentUser" }),
      this.$store.state.searchText.length > 0
        ? _c("div", { staticClass: "resultsCount" }, [
            _vm._v(
              ' "Profiles" tab shows ' +
                _vm._s(_vm.numberOfFilteredProfiles) +
                ' subset of matches for "' +
                _vm._s(this.$store.state.searchText) +
                '" '
            )
          ])
        : _vm._e(),
      this.$store.state.searchText.length == 0
        ? _c("div", { staticClass: "resultsCount" }, [
            _vm._v(' "Profiles" tab shows top profiles ranked by match. ')
          ])
        : _vm._e(),
      _c("b-container", { staticStyle: { padding: "0px 0px 0px 0px" } }, [
        _c(
          "div",
          { staticClass: "edit_form" },
          [
            _c("ValidationObserver", {
              attrs: { name: "myform" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function({ handleSubmit }) {
                    return [
                      _c(
                        "form",
                        {
                          attrs: { id: "myform" },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return handleSubmit(_vm.onSubmit)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "edit_fields" },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "name",
                                  rules: {
                                    required: true,
                                    regex: /^[A-Za-zА-Яа-яЁё]((\ |\-)?[A-Za-zА-Яа-яЁё ]){0,49}$/
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function({ errors }) {
                                        return [
                                          _c("br"),
                                          _c("label", [_vm._v("Name:")]),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.formData.name,
                                                expression: "formData.name"
                                              }
                                            ],
                                            attrs: {
                                              id: "idperson",
                                              type: "text",
                                              name: "name",
                                              placeholder: "<name>",
                                              autocomplete: "off",
                                              required: ""
                                            },
                                            domProps: {
                                              value: _vm.formData.name
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.formData,
                                                  "name",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _c("span", [
                                            _vm._v(_vm._s(errors[0]))
                                          ]),
                                          _c("br")
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("label", [_vm._v("Photo:")]),
                              _c("br"),
                              _c(
                                "div",
                                [
                                  _c("b-img", {
                                    attrs: {
                                      src: _vm.formData.photo_url,
                                      fluid: "",
                                      alt: "Responsive image"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("ValidationProvider", {
                                attrs: { name: "photo", rules: "required" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function({ errors }) {
                                        return [
                                          _c("input", {
                                            attrs: {
                                              required: "",
                                              id: "input",
                                              type: "file",
                                              accept:
                                                "image/png, image/jpeg, image/jpg",
                                              disabled: _vm.processing
                                            },
                                            on: { change: _vm.photoUpload }
                                          }),
                                          _c("span", [
                                            _vm._v(_vm._s(errors[0]))
                                          ]),
                                          _c("br")
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("label", [_vm._v("Location:")]),
                              _c("ValidationProvider", {
                                attrs: { name: "location", rules: "required" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function({ errors }) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.formData.location,
                                                expression: "formData.location"
                                              }
                                            ],
                                            attrs: {
                                              required: "",
                                              type: "text",
                                              name: "location",
                                              placeholder: "<location>"
                                            },
                                            domProps: {
                                              value: _vm.formData.location
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.formData,
                                                  "location",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _c("span", [
                                            _vm._v(_vm._s(errors[0]))
                                          ]),
                                          _c("br")
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("label", [_vm._v("Timezone:")]),
                              _c("ValidationProvider", {
                                attrs: { name: "timezone", rules: "required" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function({ errors }) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.formData.timezone,
                                                expression: "formData.timezone"
                                              }
                                            ],
                                            attrs: {
                                              required: "",
                                              type: "text",
                                              name: "timezone",
                                              placeholder:
                                                "<timezone abbreviation>"
                                            },
                                            domProps: {
                                              value: _vm.formData.timezone
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.formData,
                                                  "timezone",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _c("span", [
                                            _vm._v(_vm._s(errors[0]))
                                          ]),
                                          _c("br")
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("label", [_vm._v("Email:")]),
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "email",
                                  rules: "required|email"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function({ errors }) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.formData.email,
                                                expression: "formData.email"
                                              }
                                            ],
                                            attrs: {
                                              type: "email",
                                              name: "email",
                                              placeholder: "<email>"
                                            },
                                            domProps: {
                                              value: _vm.formData.email
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.formData,
                                                  "email",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _c("span", [
                                            _vm._v(_vm._s(errors[0]))
                                          ]),
                                          _c("br")
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "about",
                                  rules: "required|min:400"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function({ errors }) {
                                        return [
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.formData.about,
                                                expression: "formData.about"
                                              }
                                            ],
                                            staticStyle: {
                                              "font-size": "0.8em",
                                              width: "100%",
                                              "max-width": "100%"
                                            },
                                            attrs: {
                                              id: "message",
                                              name: "about",
                                              placeholder:
                                                "<Copy and paste your profile here, or write from scratch>",
                                              required: ""
                                            },
                                            domProps: {
                                              value: _vm.formData.about
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.formData,
                                                  "about",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _c("span", [
                                            _vm._v(_vm._s(errors[0]))
                                          ]),
                                          _c("br")
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("label", [_vm._v("Specializations:")]),
                              _c(
                                "div",
                                _vm._l(_vm.formData.specializations, function(
                                  specialization,
                                  index
                                ) {
                                  return _c(
                                    "span",
                                    { key: specialization.id },
                                    [
                                      _c("u", [_vm._v(_vm._s(specialization))]),
                                      index !==
                                      _vm.formData.specializations.length - 1
                                        ? _c("span", [_vm._v(" | ")])
                                        : _vm._e()
                                    ]
                                  )
                                }),
                                0
                              ),
                              _c("label", [
                                _vm._v(
                                  "Skills (" +
                                    _vm._s(_vm.formData.skills.length) +
                                    "):"
                                )
                              ]),
                              _c(
                                "div",
                                _vm._l(_vm.formData.skills, function(
                                  skill,
                                  index
                                ) {
                                  return _c("span", { key: skill.id }, [
                                    _c("u", [_vm._v(_vm._s(skill))]),
                                    index !== _vm.formData.skills.length - 1
                                      ? _c("span", [_vm._v(" | ")])
                                      : _vm._e()
                                  ])
                                }),
                                0
                              ),
                              _c(
                                "div",
                                [
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "privacy",
                                      rules: { required: { allowFalse: false } }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function({ errors }) {
                                            return [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    id: "idprivacy",
                                                    type: "checkbox",
                                                    "required:true": "",
                                                    name: "privacy",
                                                    value: "true",
                                                    "unchecked-value": "false"
                                                  },
                                                  model: {
                                                    value: _vm.formData.privacy,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "privacy",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.privacy"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " I accept the privacy conditions: "
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          "https://marmalade.ai/privacy-policy"
                                                      }
                                                    },
                                                    [_vm._v("Privacy Policy")]
                                                  )
                                                ]
                                              ),
                                              _c("span", [
                                                _vm._v(_vm._s(errors[0]))
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _vm.formData.privacy
                                    ? _c("div", [
                                        _vm._v(
                                          "State: " +
                                            _vm._s(_vm.formData.privacy)
                                        )
                                      ])
                                    : _c("div", [
                                        _vm._v("State: (not accepted)")
                                      ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "terms",
                                      rules: { required: { allowFalse: false } }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function({ errors }) {
                                            return [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    id: "idterms",
                                                    type: "checkbox",
                                                    "required:true": "",
                                                    name: "terms",
                                                    value: "true",
                                                    "unchecked-value": "false"
                                                  },
                                                  model: {
                                                    value: _vm.formData.terms,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "terms",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "formData.terms"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " I accept the terms and conditions "
                                                  )
                                                ]
                                              ),
                                              _c("span", [
                                                _vm._v(_vm._s(errors[0]))
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _vm.formData.terms
                                    ? _c("div", [
                                        _vm._v(
                                          "State: " + _vm._s(_vm.formData.terms)
                                        )
                                      ])
                                    : _c("div", [
                                        _vm._v("State: (not accepted)")
                                      ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "conduct",
                                      rules: { required: { allowFalse: false } }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function({ errors }) {
                                            return [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    id: "idconduct",
                                                    type: "checkbox",
                                                    "required:true": "",
                                                    name: "conduct",
                                                    value: "true",
                                                    "unchecked-value": "false"
                                                  },
                                                  model: {
                                                    value: _vm.formData.conduct,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "conduct",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.conduct"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " I accept code of conduct: "
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          "https://marmalade.ai/code-of-conduct"
                                                      }
                                                    },
                                                    [_vm._v("Code of Conduct")]
                                                  )
                                                ]
                                              ),
                                              _c("span", [
                                                _vm._v(_vm._s(errors[0]))
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _vm.formData.conduct
                                    ? _c("div", [
                                        _vm._v(
                                          "State: " +
                                            _vm._s(_vm.formData.conduct)
                                        )
                                      ])
                                    : _c("div", [
                                        _vm._v("State: (not accepted)")
                                      ])
                                ],
                                1
                              ),
                              _c("br"),
                              _c("br")
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }