var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login_form" },
    [
      _vm.error
        ? _c("div", { staticClass: "error" }, [
            _vm._v(_vm._s(_vm.error.message))
          ])
        : _vm._e(),
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function({ handleSubmit }) {
              return [
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.register.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "email" },
                      [
                        _c("ValidationProvider", {
                          attrs: { name: "email", rules: "required|email" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function({ errors }) {
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.email,
                                          expression: "email"
                                        }
                                      ],
                                      attrs: {
                                        type: "email",
                                        placeholder: "email"
                                      },
                                      domProps: { value: _vm.email },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) return
                                          _vm.email = $event.target.value
                                        }
                                      }
                                    }),
                                    _c("span", [_vm._v(_vm._s(errors[0]))])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "password" },
                      [
                        _c("ValidationProvider", {
                          attrs: {
                            name: "password",
                            rules: "required|max:40|min:7",
                            vid: "password"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function({ errors }) {
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.password,
                                          expression: "password"
                                        }
                                      ],
                                      attrs: {
                                        type: "password",
                                        autocomplete: "off",
                                        placeholder: "password"
                                      },
                                      domProps: { value: _vm.password },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) return
                                          _vm.password = $event.target.value
                                        }
                                      }
                                    }),
                                    _c("span", [_vm._v(_vm._s(errors[0]))])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    ),
                    _c(
                      "button",
                      {
                        attrs: { type: "submit" },
                        on: { click: _vm.register }
                      },
                      [_vm._v("Register")]
                    )
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }