var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-navbar",
        {
          staticClass: "py-1",
          attrs: { toggleable: "lg", type: "dark", variant: "info" }
        },
        [
          _c("b-navbar-brand", { attrs: { href: "#" } }, [
            _c(
              "p",
              {
                staticStyle: { position: "absolute", top: "10px", left: "60px" }
              },
              [
                _vm._v(" Marmalade AI "),
                _c("span", { staticStyle: { "font-size": "0.8em" } }, [
                  _vm._v("- virtual networking")
                ])
              ]
            ),
            _c("div", [
              _c("img", {
                staticStyle: {
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  "background-color": "white"
                },
                attrs: {
                  width: "48",
                  height: "48",
                  src: "../assets/images/" + "Marmalade_Logo_trans_white_M.png"
                }
              })
            ]),
            _c("br")
          ]),
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _c(
            "b-collapse",
            { attrs: { id: "nav-collapse", "is-nav": "" } },
            [
              _c(
                "b-navbar-nav",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "b-nav-form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.search.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "mr-sm-2",
                        attrs: {
                          size: "sm",
                          placeholder: "Search word or phrase"
                        },
                        model: {
                          value: _vm.searchText,
                          callback: function($$v) {
                            _vm.searchText = $$v
                          },
                          expression: "searchText"
                        }
                      }),
                      _c(
                        "b-button",
                        {
                          staticClass: "my-2 my-sm-0",
                          attrs: { size: "sm", type: "submit" }
                        },
                        [_vm._v("Search")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }