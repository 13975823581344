<template>
  <div>
    <br />
    <button @click="RecommenderRequest">Push for Virtual Network</button>
    <br />
    <br />
  </div>
</template>
<script>
import axios from "axios";
// import { auth } from "@/firebase";

export default {
  name: "RecommendProfiles",
  data() {
    return {
      loading: true,
      errored: false
    };
  },
  methods: {
    async RecommenderRequest() {
      console.log("In RecommendProfiles.RecommenderRequest()");
      // if (location.hostname === "localhost") {
      //   console.log("On localhost, so CORS problem");
      // }
      // const myuid = await auth.currentUser.uid;
      // const myuid = "wH3AdZ1UOBAN6WeMtgLq"; 
      const myuid = "32";
      // "PAXtfTm4jLsw3ostEQhL";
      let result = "";
      let url =
        "https://profile-recommender-service-pvfrkzbbya-uw.a.run.app?pid=" + myuid + "&max=3";
        // 2022-7-19: the url we used at 5pm was missing ".app"
        // "https://profile-recommender-service-pvfrkzbbya-uc.a.run?pid=32&max=3"
        // "https://us-central1-marmalade-dev-yc-100.cloudfunctions.net/profile_recommender_service?pid=" + myuid + "&max=3"
        // "https://us-central1-marmalade-dev.cloudfunctions.net/profile_recommender_service?pid=" +
        // "/profile_recommender_service?pid=" + myuid + "&max=3";
      // axios.head
      result = await axios
        .get(url)
        .then(response => {
          // console.log(response.data.json());
          result = response.data;
        })
        .catch(error => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
          console.log("typeof result:", typeof result);
          console.log("result: ", result);
          // result is an array
          return result;
        });
    }
  }
};
</script>
<style scoped></style>
