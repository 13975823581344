var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-col",
        [
          _c(
            "b-card",
            {
              staticClass: "md-3",
              staticStyle: { "max-width": "30rem" },
              attrs: { "no-body": "" }
            },
            [
              _c(
                "b-card-body",
                { attrs: { "no-body": "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "3" } },
                        [
                          !_vm.photoUrl ||
                          _vm.photoUrl === "../assets/images/kitty.jpg" ||
                          _vm.photoUrl === "kitty.jpg"
                            ? _c("b-card-img", {
                                attrs: {
                                  src:
                                    "https://storage.googleapis.com/marmalade-dev.appspot.com/kitty.jpg"
                                }
                              })
                            : _c("b-card-img", { attrs: { src: _vm.photoUrl } })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c("b-link", { attrs: { to: "/about" } }),
                          _c(
                            "b-card-text",
                            { staticStyle: { margin: "0px 0" } },
                            [_c("b", [_vm._v(_vm._s(_vm.name))])]
                          ),
                          _c(
                            "b-card-text",
                            { staticStyle: { margin: "0px 0" } },
                            [_vm._v(" " + _vm._s(_vm.position) + " ")]
                          ),
                          _c(
                            "b-card-text",
                            { staticStyle: { margin: "0px 0" } },
                            [
                              _c("i", [_vm._v("Specializations")]),
                              _vm._v(": "),
                              _vm._l(_vm.specializations, function(
                                specialization,
                                index
                              ) {
                                return _c("li", { key: specialization.id }, [
                                  _c("b", [_vm._v(_vm._s(specialization))]),
                                  index !== _vm.specializations.length - 1
                                    ? _c("span", [_vm._v(" | ")])
                                    : _vm._e()
                                ])
                              })
                            ],
                            2
                          ),
                          _c(
                            "b-card-text",
                            { staticStyle: { margin: "0px 0" } },
                            [
                              _c("i", [_vm._v("Skills")]),
                              _vm._v(": "),
                              _vm._l(_vm.skills, function(skill, index) {
                                return _c("span", { key: skill.id }, [
                                  _c("u", [_vm._v(_vm._s(skill))]),
                                  index !== _vm.skills.length - 1
                                    ? _c("span", [_vm._v(" | ")])
                                    : _vm._e()
                                ])
                              })
                            ],
                            2
                          ),
                          _c(
                            "b-card-text",
                            { staticStyle: { margin: "0px 0" } },
                            [_vm._v(" " + _vm._s(_vm.email) + " ")]
                          ),
                          _c(
                            "b-card-sub-title",
                            {
                              staticClass: "card-listing",
                              staticStyle: { margin: "0px 0" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.validTimezone) +
                                  " " +
                                  _vm._s(_vm.shortenedLocation) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-card-text",
                        {
                          staticStyle: {
                            "padding-left": "15px",
                            "font-size": "0.9em"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.about) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }