var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("current-user", { staticClass: "currentUser" }),
      this.$store.state.searchText.length > 0
        ? _c("div", { staticClass: "resultsCount" }, [
            _vm._v(
              " matches within your matches: " +
                _vm._s(_vm.numberOfFilteredProfiles) +
                ' matches for "' +
                _vm._s(this.$store.state.searchText) +
                '" '
            )
          ])
        : _vm._e(),
      this.$store.state.searchText.length == 0
        ? _c("div", { staticClass: "resultsCount" }, [
            _vm._v(
              " Showing profiles sorted by match. " +
                _vm._s(this.$store.state.virtual_profiles.length) +
                " "
            )
          ])
        : _vm._e(),
      _c(
        "b-container",
        _vm._l(this.$store.state.virtual_profiles, function(profile) {
          return _c("profile-card", {
            key: profile.uid,
            attrs: {
              about: profile.about,
              conduct: profile.conduct,
              created_timestamp: profile.created_timestamp,
              email: profile.email,
              last_modified_timestamp: profile.last_modified_timestamp,
              linkedin: profile.linkedin,
              location: profile.location,
              meetings: profile.meetings,
              name: profile.name,
              "photo-url": profile.photo_url,
              position: profile.position,
              privacy: profile.privacy,
              score: profile.score,
              skills: profile.skills,
              specializations: profile.specializations,
              terms: profile.terms
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }