// import { db, db1, db2 } from "@/firebase";
import "firebase/auth";
import { db } from "@/firebase";
import { firestoreAction, vuexfireMutations } from "vuexfire";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    profiles: [],
    virtual_profiles: [],
    searchText: "",
    randomNumber: "",
    datasource: "marmalade-dev",
    database: "db",
    user: ""
  },
  mutations: {
    ...vuexfireMutations,
    setSearch(state, payload) {
      // console.log("In mutations/setSearch and the payload is: " + payload);
      state.searchText = payload;
    },
    updateDatasource(state, datasource) {
      console.log(
        "In store/index.js, updateDatasource, changing " +
        state.datasource +
        " to " +
        datasource
      );
      state.datasource = datasource;
      switch (datasource) {
        case "marmalade-dev":
          console.log("In store/index.js, Got marmalade-dev");
          this.dispatch("bindProfiles", db);
          break;
        // case "marmalade-dev":
        //   console.log("In store/index.js. Got marmalade-dev");
        //   this.dispatch("bindProfiles", db2);
        //   break;
        default: 
          console.log("In store/index.js. Got something other than expected");
      }
      console.log(
        "We will change the database here, currently it is: " + state.database
      );
    }
  },
  actions: {
    /**
     * @param context deconstructed to get only the method to create the ref
     */

    // See Vuexfire, "binding/subscribing to changes," https://vuefire.vuejs.org/vuexfire/binding-subscriptions.html
    // - bind in actions
    // - unbindFirestoreRef to stop being in sync (no need to wait for a promise)
    // - collections & queries are bound as arrays; documents as objects
    // - Always return or await the promise returned by bindFirestoreRef/bindFirebaseRef
    // - However, unbinding is automatic when you bind over existing bindings
    bindProfiles: firestoreAction(({ bindFirestoreRef }, mydb) => {
      console.log("In store/index.bindProfiles:" + JSON.stringify(mydb.app, null, 2));
      return bindFirestoreRef("profiles", mydb.collection("profiles"));
    }),
    // unbindProfiles:
    // this.$store.dispatch("bindProfiles");
    addProfile: firestoreAction((context, payload) => {
      console.log("In store/index.addProfile");
      return db.collection("profiles").add(payload);
    }),
    deleteProfile: firestoreAction((context, payload) => {
      console.log("In store/index.deleteProfile");
      db.collection("profiles")
        .doc(payload)
        .delete();
    }),
    updateProfile: firestoreAction((context, payload) => {
      console.log("In store/index.updateProfile");
      db.collection("profiles")
        .doc(payload.id)
        .set(payload);
    }),
    search(state, payload) {
      // console.log("In actions/search and the payload is: " + payload);
      console.log("In store/index.search()");
      state.commit("setSearch", payload);
    }
  },
  getters: {
    profiles(state) {
      // console.log("In getter: profiles");
      console.log("In store/index.getters.profiles()");
      return state.profiles; //.filter('name', 'asc');
    }
  },
  modules: {}
});
