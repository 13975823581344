<template>
  <span>
    <form style="display: inline-block">
      <label class="navbar_database_prompt">Database: </label>
      <input type="datasource" required v-model="datasource" />
      <!-- <div v-if="onLoginOrRegister"> -->
      <div v-if="this.$route.name == 'login' || this.$route.name == 'register'">
        <select v-model="datasource" style="background: transparent">
          <option value="marmalade-dev">marmalade-dev</option>
          <!-- <option value="marmalade-dev">marmalade-dev</option> -->
          <option value="marmalade-production">marmalade-production</option>
        </select>
      </div>
      <div v-elseCurrent database: {{ this.$store.state.datasource }}</div>
      <!-- Not on Login or Register Page -->
      <!-- <div>
        <label>
          <input type="checkbox" v-model="terms" required />
          I accept the terms and conditions
        </label>
      </div> -->
    </form>
    <!-- <p>Datasource: {{ datasource }}</p> -->
    <!-- <p>Terms accepted: {{ terms }}</p> -->
  </span>
</template>

<script>
export default {
  data() {
    return {
      // datasource: "Freelancers (short)",
      terms: false
    };
  },
  computed: {
    datasource: {
      get() {
        return this.$store.state.datasource;
      },
      set(value) {
        this.$store.commit("updateDatasource", value);
      },
      onLoginOrRegister() {
        return (this.$route.name == "login" || this.$route.name == "register");
      }
    }
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.navbar_database_prompt {
  color: black;
  text-transform: none;
  font-size: 1em;
  font-weight: normal;
}
// form {
//   max-width: 420px;
//   margin: 30px auto;
//   background: white;
//   text-align: left;
//   padding: 40px;
//   border-radius: 10px;
// }
// input[type="checkbox"] {
//   color: red;
//   display: inline-block;
//   width: 16px;
//   margin: 0 10px 0 0;
//   position: relative;
//   top: 2px;
// }
// label {
//   color: #aaa;
//   display: inline-block;
//   margin: 25px 15px 15px 15px;
//   font-size: 0.6em;
//   text-transform: uppercase;
//   letter-spacing: 1px;
//   font-weight: bold;
// }
// input {
//   display: block;
//   padding: 10px 6px;
//   width: 100%;
//   box-sizing: border-box;
//   border: none;
//   border-bottom: 1px solid #ddd;
//   color: #555;
// }
</style>
