var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login_form" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.login.apply(null, arguments)
          }
        }
      },
      [
        _c("div", { staticClass: "login" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email"
              }
            ],
            attrs: { type: "email", placeholder: "email" },
            domProps: { value: _vm.email },
            on: {
              input: function($event) {
                if ($event.target.composing) return
                _vm.email = $event.target.value
              }
            }
          })
        ]),
        _c("div", { staticClass: "password" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password"
              }
            ],
            attrs: {
              type: "password",
              autocomplete: "off",
              placeholder: "password"
            },
            domProps: { value: _vm.password },
            on: {
              input: function($event) {
                if ($event.target.composing) return
                _vm.password = $event.target.value
              }
            }
          })
        ]),
        _c("button", { attrs: { type: "submit" } }, [_vm._v("Login")])
      ]
    ),
    _vm.error
      ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error.message))])
      : _vm._e(),
    _c(
      "span",
      [
        _vm._v("Need an account? Click here to "),
        _c("router-link", { attrs: { to: "/register" } }, [_vm._v("register")])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }