<template>
  <div class="login_form">
    <form @submit.prevent="login">
      <div class="login">
        <input type="email" placeholder="email" v-model="email" />
      </div>
      <div class="password">
        <input type="password" autocomplete="off" placeholder="password" v-model="password" />
      </div>
      <button type="submit">Login</button>
    </form>
    <div class="error" v-if="error">{{ error.message }}</div>
    <span
      >Need an account? Click here to
      <router-link to="/register">register</router-link>
    </span>
  </div>
</template>

<script>
import { auth } from "@/firebase";
// import firebase from "firebase/app";
// import "firebase/auth";

const DEBUG = false;

export default {
  data() {
    return {
      email: "",
      password: "",
      error: ""
    };
  },
  // beforeCreate() {},
  // created() {},
  // beforeMounted() {}.
  mounted() {
    if (DEBUG) {
      console.log("Login.mounted.");
    }
  },
  // beforeUpdate() {},
  updated() {
    if (DEBUG) {
      console.log("Login.updated");
    }
  },
  // beforeDestroy() {},
  destroyed() {
    if (DEBUG) {
      console.log("Login.destroyed");
    }
  },
  methods: {
    async login() {
      console.log("/Login.login()");
      try {
        const val = await auth.signInWithEmailAndPassword(
          this.email,
          this.password
        );
        console.log(
          "/Login.login() val.user.uid in Login.vue is: ",
          val.user.uid,
          " /Login.login() val.user.email is: ",
          val.user.email
        );
        this.$router.push({ name: "myprofile" });
      } catch (err) {
        console.log(err);
      }
      console.log("/Login end");
    }
  }
};
</script>
<!-- 
input { width: 400px; padding: 30px; margin: 20px; font-size: 21px; } button {
width: 400px; height: 75px; font-sze: 100%; } .error { color: red; font-size:
18px; } .loginForm { font-size: 21; color: red; }
-->
<style lang="scss">
// .login_form {
//   // color: orange;
//   margin-left: 20px;
//   font-family: Avenir, Helvetica, Arial, sans-serif;
// }
</style>
