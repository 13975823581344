<template>
  <div>
    <b-col>
      <b-card no-body style="max-width: 30rem" class="md-3">
        <b-card-body no-body>
          <b-row>
            <b-col md="3">
              <!-- <b-card-img height="80" width="80" src="https://picsum.photos/600/300/?image=11"></b-card-img> -->
              <!-- For b-card-img src syntax below, see https://bootstrap-vue.org/docs/reference/images -->

              <!-- <b-card-text style="margin: 0px 0">
                test {{ photoUrl }}
              </b-card-text> -->
              <b-card-img
                v-if="!photoUrl || photoUrl === '../assets/images/kitty.jpg' || photoUrl === 'kitty.jpg'"
                :src="'https://storage.googleapis.com/marmalade-dev.appspot.com/kitty.jpg'"
              ></b-card-img>
              <b-card-img v-else :src="photoUrl"></b-card-img>
            </b-col>
            <b-col>
              <b-link to="/about">
                <!-- <h4>{{ name }}</h4>{{ score }} -->
              </b-link>

              <b-card-text style="margin: 0px 0">
                <b>{{ name }}</b>
              </b-card-text>
              <b-card-text style="margin: 0px 0">
                {{ position }}
              </b-card-text>

              <b-card-text style="margin: 0px 0">
                <!-- <i>Specializations (score: {{ score.toFixed(2) }} )</i>: -->
                <i>Specializations</i>:
                <li v-for="(specialization, index) in specializations" :key="specialization.id">
                  <b>{{ specialization }}</b><span v-if="index !== (specializations.length - 1)"> | </span>
                </li>
              </b-card-text>
              <!-- {{ stringFromList(specializations) }} -->
              <b-card-text style="margin: 0px 0">
                <i>Skills</i>:
                <span v-for="(skill, index) in skills" :key="skill.id">
                  <u>{{ skill }}</u><span v-if="index !== (skills.length - 1)"> | </span>
                </span>
              </b-card-text>

              <b-card-text style="margin: 0px 0">
                {{ email }}
              </b-card-text>
              <!-- <b-card-text style="margin: 0px 0">
                <i>Contact info</i>:
                {{ getContactInfoAsString }}
              </b-card-text>
              <b-card-text style="margin: 0px 0">
                {{ portfolio }}
              </b-card-text> -->
              <b-card-sub-title class="card-listing" style="margin: 0px 0;">
                {{ validTimezone }} {{ shortenedLocation }}
              </b-card-sub-title>
            </b-col>
          </b-row>
          <b-row>
            <b-card-text style="padding-left: 15px; font-size: 0.9em">
              {{ about }}
            </b-card-text>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
  </div>
</template>

<script>

export default {
  props: {
    about: { type: String, default: "" },
    // contactInfo: { type: Array, default: () => ["", ""] },
    conduct: "not accepted",
    createdTimestamp: { type: String, default: "" },
    email: { type: String, default: "" },
    // id: { type: String, default: "" },
    lastModifiedTimestamp: { type: String, default: "" },
    linkedin: { type: String, default: "" },
    location: { type: String, default: "" },
    // meetings: { type: Array, default: new Array("test") },
    name: { type: String, default: "" },
    photoUrl: { type: String, default: "" },
    // portfolio: { type: String, default: "" },
    position: { type: String, default: "" },
    privacy: "not accepted",
    score: { type: Number, default: 0 },
    skills: { type: Array, default: () => [] },
    specializations: { type: Array, default: () => [] },
    terms: "not accepted",
    timezone: { type: String, default: "" },
    contact: { type: Array, default: () => [] }
  },
  methods: {
    // CF20220310: probably want this to be a "computed" not a "method" so it will be cached.

    // stringFromList(s) {
    //   console.log("In stringFromList, the incoming string, s, is: " + s);
    //   return s.replace(/’|‘|\[|]/g, "").replace(/,/g, ", ");
    // }
  },
  computed: {
    modalId() {
      return `modal-${this.linkedin}`;
    },
    getContactInfoAsString: function() {
      console.log("In computed: getContactInfoAsString");
      let strContactInfo = "Contact info goes here.";
      // let contact = [];
      // for (let i = 0; i < (this.contactInfo.length); i++){
      //   // if ((this.contactInfo[i]["twitter"]) !== "NA") { 
      //     // contact.push(this.contactInfo[i]["twitter"]); 
      //     // contact.push("Got one");
      //     strContactInfo += "+";
      //     console.log("In contactInfo loop");
      //   };
      // }
      // strContactInfo = contact.join(", ");
      return strContactInfo;
    },
    // <!-- <P v-for="(key, value) in contactInfo" :key="value"> -->
    //   <!-- {{ key }}: {{ value }} -->
    //   <!-- <span v-if="key.email != 'NA'">{{ key.email }}</span>
    //   <span v-if="key.phone != 'NA'">{{ key.phone }}</span>
    //   <span v-if="key.twitter != 'NA'">{{ key.twitter }}</span>
    //   <span v-if="key.websites != 'NA'">
    //     <span v-for="website in key.websites" :key="website.id">
    //       https://{{ website }}
    //     </span>
    //   </span>
    // </P> -->

    // Put parenthesis around the timezone; return nothing if emppty.
    validTimezone: function() {
      let tzString = "";
      if (this.timezone) {
        tzString = "(" + this.timezone + ")";
      } else {
        tzString = "";
      }
      return tzString;
    },
    shortenedLocation: function() {
      return this.location.replace("United States", "U.S.");
    },
    // CF20220310: probably want this to be a "computed" not a "method" so it will be cached.
    // stringFromList() {
    //   // console.log(myList);
    //   return "bongo";
    // }
  }
};
</script>

<style lang="scss" scoped>
h4 {
  font-weight: 600;
  color: #37827b;
}
.b-card-img {
  width: 100%;
  height: 15vw;
  object-fit: cover;
}
.card {
  border: 1px solid black;
}
.card-listing {
  color: red;
}
// For tests: outline the cards areas?
// .col {
//   border: 1px solid black;
// }
// .row {
//   border: 1px solid black;
// }
.no-following-line {
  color: red;
}
</style>
