import { auth } from "../firebase";
// import firebase from "firebase/app";
// import "firebase/auth";

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Profiles from "../views/profiles/Profiles.vue";
import MyProfile from "../views/profiles/MyProfile.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import NotFound from "../components/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { requiresAuth: false }
  },
  {
    path: "/myprofile",
    name: "myprofile",
    component: MyProfile,
    // props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/profiles",
    name: "profiles",
    component: Profiles,
    // props: true,
    meta: { requiresAuth: true }
  },
  // {
  //   meta: { requiresAuth: false }
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   // component: () =>
  //   //   import(/* webpackChunkName: "about" */ "../views/About.vue")
  // },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: { requiresAuth: false }
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
    meta: { requiresAuth: false }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = auth.currentUser;
  if (requiresAuth && !isAuthenticated) {
    next("/login");
  } else {
    next();
  }
});

export default router;
