// src/firebase.js

// 2022-8-10 branch for "marmalade-dev" that hopefully only 
// requires changes in this file.

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
// import { FUNCTION_STATUS_HEADER_FIELD } from "@google-cloud/functions-framework/build/src/types";

// config of "marmalade-dev":

const firebaseConfig = {
  apiKey: "AIzaSyAZMedpSa8IkF5Id2AvOXgjsP2WT3kZdpw",
  authDomain: "marmalade-dev.firebaseapp.com",
  projectId: "marmalade-dev",
  storageBucket: "marmalade-dev.appspot.com",
  messagingSenderId: "157106770279",
  appId: "1:157106770279:web:442fc919f9b9ed89ec5fc2"
};

firebase.initializeApp(firebaseConfig)
const db = firebase.firestore(); 
const auth = firebase.auth();


const projectStorage = firebase.storage();
console.log("/firebase projectStorage:", projectStorage);

// Note: no "auth" or "storage" exported.

// For switching to local emulator:
// console.log("/firebase projectStorage:", projectStorage);

// if (location.hostname === "localhost") {
//   auth.useEmulator("http://localhost:9099");  //, { disableWarnings: true } , 
//   db.useEmulator("localhost", 8080);
//   projectStorage.useEmulator("localhost", 9199);
//   // functions.useEmulator("localhost", 5001);
//   console.log("Detected localhost, so set auth 9099, db 8080, storage 9199");
// }

export { firebase, db, auth, projectStorage };  // auth
