var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("br"),
    _c("button", { on: { click: _vm.RecommenderRequest } }, [
      _vm._v("Push for Virtual Network")
    ]),
    _c("br"),
    _c("br")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }